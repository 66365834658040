.ca__gridCardProject:hover{
    cursor: pointer;
}

.ca__gridCardProject-actions-modal {
    background: #FFFFFF;
    border: 1px solid #E6ECEF;
    border-radius: 8px;
    position: absolute;
    top: 125%;
    right: 20px;
    z-index: 1;

}

.ca__gridCardProject-edit,
.ca__gridCardProject-delete {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    padding: 10px 19px;
    &:hover{
        background-color: #f5f5f5;
        cursor: pointer;
    }
    img{
        margin-right: 10px;
    }
}


.ca__projects-list {
    .ca__gridCardProject {
        width: 100%;
        background: $blanco;
        border-radius: 4px;
        margin-bottom: 18px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // padding: 18px;
    }

    .ca__gridCardProject-image {
        width: 7%;
        height: 44px;
        // background-color: red;
    }

    .ca__gridCardProject-info {
        width: 100%;
        display: flex;
        padding: 19.5px 18px;
        // background-color: rgb(111, 179, 216);
        // justify-content: space-between;
        &>h4 {
            width: 59%;
        }

        .ca__gridCardProject-info-category,
        &>p {
            width: 25%;
        }

    }

    .ca__gridCardProject-numPages {
        display: none;
    }

    .ca__gridCardProject-actions {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding-right: 18px;
        &> img:hover{
            cursor: pointer;
        }
    }
}

@media screen and (min-width: 2000px) {
    .ca__projects-list .ca__gridCardProject-info>h4 {
        width: 56%;
    }
}