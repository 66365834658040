.ca__sidebar {
    box-sizing: border-box;
    height: 100vh;
    width: 17%;
    background-color: #fff;
    padding: 24px 18px;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ca__sidebar-logo {
    width: 50%;
    margin-bottom: 24px;

    &:hover {
        cursor: pointer;
    }
}

.ca__sidebar-item {
    padding: 10px;
    margin-bottom: 8px;
    border-radius: 8px;

    &:hover {
        cursor: pointer;
    }
}

.ca__item-active {
    background-color: $gris5;
}
.ca__sidebar-profile{
    border-top: 1px solid #F0F6FC;
    padding-top: 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    i{
        color: $gris2;
        font-size: 18px;
    }
    &:hover {
        cursor: pointer;
    }
}

.ca__sidebar-profile-containerPhoto {
    display: flex;
    align-items: center;

    p {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #242A30;
        padding-left: 12px;
    }
}
.ca__header-submenu {
    position: absolute;
    background: #FFFFFF;
    border: 1px solid #E6ECEF;
    border-radius: 8px;
    z-index: 1;
    top: 36%;
    right: -175px;
    width: 150px;

    div {
        padding: 9px 14px;

        &:hover {
            cursor: pointer;
            background-color: #f5f5f5;
        }
    }

}