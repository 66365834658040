.ca__loginScreen {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ca__loginScreen-container {
    max-width: 992px;
    width: 100%;
    min-height: 477px;
    display: flex;
    box-shadow: 0px 4px 20px rgba(79, 82, 88, 0.2);
    border-radius: 8px;
    background-color: $blanco;

    &>div:nth-child(1) {
        display: flex;
        flex-direction: column;
        padding: 56px 56px 0 0;
        width: 45%;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        border-left: 11px solid $azulPrimario;
        border-right: 2px solid $gris5;
        background: linear-gradient(202.42deg, rgba(211, 226, 255, 0.6) 0%, #FFFFFF 48.55%);

        img {
            width: 20%;
        }

        .ca__login-icon {
            width: 60%;
            margin-top: 18px;
            margin-bottom: 18px;
        }

        .ca__login-iconletters,
        .ca__login-icon {
            padding-left: 56px;
        }

        p {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: $gris1;
            padding-left: 56px;
        }
    }

    .ca__login-pc {
        width: 90% !important;
        margin-top: 27px;
    }

    &>div:nth-child(2) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 55%;
        // background-color: red;
        // padding: ;
    }
}

.ca__loginScreen-container-info {
    width: 80%;

    h4 {
        font-family: $font-family;
        color: $negro;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 29px;
    }
    .ca__recover-title{
        margin-bottom: 18px;
    }

    .ca__recover-info {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #494E57;
        text-align: start;
        &:hover{
            cursor: auto;
        }
    }

    &>div:nth-child(2), .ca__recover-inputs, .ca__login-container-forgot {
        &>div {
            position: relative;

            img {
                position: absolute;
                top: 29px;
                left: 20px;
                width: 18px;
            }
            i{
                position: absolute;
                top: 30px;
                right: 15px;
                font-size: 18px;
                color: $negro;
                &:hover{
                    cursor: pointer;
                }
            }
        }

        label {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: $negro;
        }

        input[type="email"] {
            margin-bottom: 24px;
        }

        .ca__login-password {
            margin-bottom: 12px;
            padding-right: 35px;
        }

        input::placeholder {
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 21px;
            color: $gris2;
        }

        input {
            width: 100%;
            margin-top: 13px;
            padding: 13.5px 18px 13.5px 50px;
            border: 1px solid $gris4;
            border-radius: 4px;
            font-size: 18px;
            line-height: 21px;
            box-sizing: border-box;
            //   margin-bottom: 12px;
            &:focus {
                border-color: #185ADB;
                border-width: 2px;
            }
        }
    }

    &>p {
        text-align: end;
        // float: right;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: $azulPrimario;
        margin-bottom: 32px;

        &:hover {
            cursor: pointer;
        }
    }
}

.ca__loginScreen-btnLogin {
    background-color: $azulPrimario;
    padding: 16px 0;
    border-radius: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: $blanco;
    text-transform: uppercase;
    text-align: center;

    &:hover {
        cursor: pointer;
    }
}

.ca__recoverPass-volver {
    margin-top: 50px;
    width: max-content;
    float: right;
    &:hover {
        cursor: pointer;
    }
}

.ca__login-error {
    background-color: rgb(245, 56, 56);
    border-radius: 4px;
    padding: 5px 1rem;
    margin-bottom: 10px;

    // position: absolute;
    // top: 2%;
    // right: .5%;
    p {
        margin: 0;
        color: #fff;
        font-weight: 500;
        font-size: 0.85rem;
    }
}



@media only screen and (max-width: 768px) {
    .ca__loginScreen {
        width: 80%;
        margin: 0 auto;
    }

    .ca__loginScreen-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border-top: 11px solid $azulPrimario;
        // border-right: 2px solid $gris5;
        padding-bottom: 25px;

        &>div:nth-child(1) {
            border: none;
            width: 100%;
            display: flex;
            justify-content: center;
            text-align: center;
            padding: 18px;

            .ca__loginScreen-container-logo {
                padding: 0 15px;
            }
        }

        &>div:nth-child(2) {
            width: 80%;
        }
    }



    .ca__loginScreen-container-info {

        h4 {
            text-align: center;
        }
    }
}

.ca__login-icon {
    margin-top: 18px;
}

.ca__forgot-error{
    margin-bottom: 20px;
    color: red;
    font-size: 16px;
}
.ca__forgot-password{
    margin-bottom: 24px!important;
}
.ca__buttonforgot{
    margin-top: 20px;
}