.ca__warningComponent {
    background-color: #fff;
    // width: 50%;
    border-radius: 4px;
    padding: 32px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    max-width: 500px;
    width: 100%;

    
    h4 {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        text-align: center;
        color: #242A30;
        text-align: center;
        width: 85%;
        margin-top: 17px;
        margin-bottom: 18px;
    }

    p {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: #242A30;
    }
}

.ca__warningComponent-close {
    position: absolute;
    right: 32px;
    top: 32px;

    &:hover {
        cursor: pointer;
    }
}

.ca__warningComponent-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 30px;
}

.ca__warningComponent-cancel {
    background: #F0F6FC;
    border-radius: 4px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #185ADB;
    padding: 16px 20px;
    // width: 30%;
    border: none;

    &:hover {
        cursor: pointer;
    }
}

.ca__warningComponent-accept {
    background: #185ADB;
    border-radius: 4px;
    // width: 45%;
    padding: 16px 20px;
    border: none;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;

    &:hover {
        cursor: pointer;
    }
}

.ca__warningComponent-show {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 24px;
    margin-bottom: 18px;

    p {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        color: #242A30;
        padding-left: 10px;
    }

    input {
        border: 1px solid #C2CCDD;
        border-radius: 2px;
        &:hover{
            cursor: pointer;
        }
    }
}