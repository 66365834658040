.ca__userplan {
    margin-top: 32px;
    border-top: 1px solid $gris4;
    padding-top: 33px;

    &>h4 {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        color: #242A30;
        margin-bottom: 24px;
    }
}

.ca__userplan-basic {
    border: 1px solid #E0E6F0;
    box-shadow: 0px 0px 6px rgba(24, 90, 219, 0.3);
    border-radius: 4px;
    padding: 21px 20px;
    margin-bottom: 24px;
}

.ca__userPlan-pro {
    border: 1px solid #E0E6F0;
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 29px;
}

.ca__userPlan-pro-divider {
    height: 1px;
    background: #E0E6F0;
    margin-top: 20px;
    margin-bottom: 20px;
}

.ca__userPlan-infoTokens {
    display: flex;
    align-items: center;
}

.ca__userPlan-infoTokens-img {
    width: 25%;
    margin-right: 5px;

    img {
        width: 80%;
    }
}

.ca__userPlan-infoTokens-text {
    h4 {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #242A30;
        margin-bottom: 8px;
    }

    p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #494E57;
    }
}

.ca__userPlan-footer {
    margin-top: 32px;
    border-top: 1px solid $gris4;
    padding-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #8E99AD;

        &:hover {
            cursor: pointer;
        }
    }

    &>div {
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: #E0E6F0;
        margin: 0 8px;
    }
}