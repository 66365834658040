.ca__createElements {
    // width: 516px;
    width: 45%;
    max-height: 600px;
    background: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04), 0px 12px 16px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    position: relative;
    padding: 24px 0;
    box-sizing: border-box;
    overflow-y: auto;

    h4 {
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 37px;
        color: $negro;
        margin-bottom: 24px;
        text-align: center;
    }
}

.ca__createElements-title {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
}

@media screen and (min-width: 1500px) {
    .ca__createElements {
        // width: 50%;
        max-height: 80%;
    }
}

@media screen and (min-width: 1800px) {
    .ca__createElements {
        width: 40%;
        // max-height: 80%;
    }
}

.ca__createElements-steps {
    width: 100%;
    height: 2px;
    background: #F0F6FC;
    position: relative;
}

.ca__createElements-step {
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: $azulPrimario;
    height: 4px;
}

.ca__createElements-close {
    position: absolute;
    top: 32px;
    right: 32px;

    &:hover {
        cursor: pointer;
    }
}

.ca__createElements-form {
    padding: 32px;

    label {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        color: $negro;
        width: 100%;
    }

    input,
    select {
        border: 1px solid $gris4;
        border-radius: 4px;
        padding: 14px 18px;
        width: 100%;
        margin-top: 12px;
        margin-bottom: 24px;
        box-sizing: border-box;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
    }
}

.ca__createElements-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 32px;
}

.ca__createElements-flexend {
    justify-content: flex-end;
}

.ca__createElements-back {
    background: $gris5;
    border-radius: 4px;
    padding: 16px 20px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: $azulPrimario;
    border: 1px solid $gris5;
    text-align: center;
    box-sizing: border-box;

    &:hover {
        cursor: pointer;
        border: 1px solid #185ADB;
    }
}

.ca__createElements-next {
    // width: 60%;
    text-align: center;
    padding: 16px 20px;
    background: #185ADB;
    border-radius: 4px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
    box-sizing: border-box;
    border: none;

    &:hover {
        cursor: pointer;
        background: #0B3B98;
    }
}

.ca__step2 {
    margin-left: 36px;
    margin-right: 31px;
    margin-top: 32px;

    h4 {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        color: $negro;
        text-align: left;
        margin-top: 20px;
    }

    label {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        color: $negro;
    }

    input,
    textarea,
    select {
        border: 1px solid #E0E6F0;
        border-radius: 4px;
        padding: 14px 16px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        margin-top: 12px;
        margin-bottom: 24px;
        width: 100%;
        box-sizing: border-box;
    }

    input::placeholder,
    textarea::placeholder {
        color: $gris2;
    }

    textarea {
        resize: none;
        height: 120px;
    }

    .ca__form-brief {
        margin-bottom: 5px;
    }

    .ca__form-feature {
        margin-bottom: 5px;

        &:last-of-type {
            margin-bottom: 20px;
        }
    }
}

.ca__createElements-container {
    max-height: 350px;
    overflow: auto;
    margin-bottom: 10px;
}

@media screen and (min-width: 1440px) {
    .ca__createElements-container {
        max-height: none;
    }
}