.ca__editor {
    padding: 24px 21px;
    width: 50%;
    height: calc(100vh - 89px);
    overflow: auto;
    box-sizing: border-box;

    // &::-webkit-scrollbar-track {
    //     background-color: blue!important;
    // }

    // &::-webkit-scrollbar-thumb {
    //     background-color: rgba(0, 0, 0, 0.2);
    // }
}


.ca__editor-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 19px;

    h4 {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        color: #000000;
    }
}

.k-content {
    font-family: $font-family;
}

.k-editor {
    border: none;
    border-style: none !important;
}

.k-editor-content iframe {
    height: 350px !important;
    // max-height: 400px;
}

.k-toolbar {
    background-color: #fff !important;
}

.ca__editor-card {
    background: #FFFFFF;
    border: 1px solid #E0E6F0;
    border-radius: 4px;
    padding: 18px;
    margin-bottom: 20px;

    .ca__secondButton {
        margin-top: 30px;
    }
}

.ca__editor-history-active {
    background-color: #185ADB !important;
    color: #fff !important;
}

.ca__history-title {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: $negro;
    margin-bottom: 18px;
}

.ca__laodingEditor {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    height: 100%;
    // width: 53%;

    p {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: #242A30;
        padding-top: 50px;
    }
}

.ca__editor-loading {
    min-height: 100vh;
    height: 100%;
    overflow: auto;
}

.ca__editor-versions {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #000000;
    margin-top: 40px;
    margin-bottom: 24px;
}

.ca__editor-card-date {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 27px;
}

.ca__editor-date {
    display: flex;
    align-items: center;

    p {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        color: $negro;
        display: flex;
        align-items: center;
        margin-right: 30px;

        i {
            margin-right: 8px;
            color: $gris2;
            font-size: 20px;
        }
    }
}

.ca__editor-more {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-decoration-line: underline;
    color: $azulPrimario !important;

    &:hover {
        cursor: pointer;
    }
}

.ca__cardCopy-favorite {
    background: $gris5;
    border-radius: 4px;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        cursor: pointer;
    }

    i {
        color: $azulPrimario;
        // font-weight: bold;
        font-size: 18px;
    }
}

.ca__copyCard-buttonsFlex {
    display: flex;
}

.ca__button-delete {
    margin-left: 12px;
}

.ca__editor-favorites-noData {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 80px;

    div {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h4 {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 21px;
            color: $negro;
            margin-bottom: 11px;
            margin-top: 8px;
        }

        p {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: $negro;
            width: 80%;
        }
    }

}