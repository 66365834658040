.ca__counter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding: 0 2px;
    margin-top: 5px;
    margin-bottom: 5px;
    p {
        font-style: normal;
        font-weight: 700;
        color: #8E99AD;
        font-size: 12px;
        font-style: italic;
    }
}

.ca__counter-warning {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #BD8E1E;

    &>span {
        font-weight: 700;
        margin-left: 5px;
    }

    img {
        margin-right: 9px;
    }

}
// .ca__editComponent .ca__counter {
    // justify-content: flex-end;
// }
.ca__editComponent .ca__counter-warning {
    display: none;
   
}