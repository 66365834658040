.ca__contents {
    display: flex;
    flex-wrap: wrap;
}

.ca__contentCard-dateEdit {
    display: flex;
    align-items: center;
    margin-top: 8px;
    
    p {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: $gris2;
        &:nth-child(1){
            margin-right: 18px;
        }
    }
}