.ca__edit {
    width: 50%;
    background: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04), 0px 12px 16px rgba(0, 0, 0, 0.03);
    box-sizing: border-box;
    height: calc(100vh - 89px);
    border-right: 4px solid #EBF0F6;
    padding: 24px 28px;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    overflow: auto;
    &::-webkit-scrollbar{
        display: none;
        // width: 100px;
    }
}
.ca__full-height{
    min-height: 85vh;
    height: auto;
}

.ca__edit-button {
    margin-top: 30px;
    button {
        float: right;
    }
}

.ca__edit-inputs-title {
    display: flex;
    align-items: center;
    margin-bottom: 22px;

    &>h4 {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        color: #000000;
    }

    i {
        margin-right: 12px;
        color: #000;
        font-size: 22px;
    }
}

.ca__edit-inputs {
    label {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: $negro;
    }

    input,
    select,
    textarea {
        margin-top: 8px;
        margin-bottom: 25px;
    }
    textarea{
        height: 120px;
    }
}