.ca__message {
    position: fixed;
    max-width: 350px;
    min-width: 200px;
    right: 8px;
    top: 30px;
    border-radius: 4px;
    padding: 12px 18px;
    box-sizing: border-box;
    z-index: 2;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: $negro;

    .ca__message-icondone {
        display: none;
    }

    svg {
        margin-right: 9px;
    }
}

.ca__message-error {
    background-color: #ffdfdf;
    border: 1px solid #fbb3b3;
    svg path{
        fill: #db4920;
    }
}

.ca__message-warning {
    background-color: #FFFEDF;
    border: 1px solid #FBF8B3;
}

.ca__message-info {
    background-color: #dfecff;
    border: 1px solid #b3d3fb;
    svg path{
        fill: #20a0db;
    }
}

.ca__message-done {
    background: #EEFFEE;
    border: 1px solid #11B114;

    svg {
        display: none;
    }

    .ca__message-icondone {
        display: block;
        margin-right: 9px;
    }
}