.ca__contentCard{
    position: relative;
    width: 30%;
    background: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.03), 0px 6px 10px rgba(0, 0, 0, 0.02);
    border-radius: 8px;
    padding: 0 16px 16px 16px;
    margin-right: 40px;
    margin-bottom: 32px;
    box-sizing: border-box;
    border: 1px solid #fff;
    &:hover {
        cursor: pointer;
        border-color: $azulPrimario;
    }

}

.ca__contentCard-impar {
    margin-right: 0;
}

.ca__contentCard-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $gris5;
    position: relative;
    z-index: 1;
    height: 60px;

    h4 {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        color: #000000;
        width: 80%;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        // padding-top: 15px;
        // padding-bottom: 8px;
    }

    img:hover {
        cursor: pointer;
    }
}

.ca__contentCard-title-icon {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    right: 10px;
    padding-left: 2px;
    top: 20px;

    .ca__header-submenu {
        width: max-content;
        top: 110%;
        right: initial;
        left: 0;

        div {
            display: flex;
            align-items: center;

            i {
                margin-right: 5px;
            }
        }
    }
}
.ca__content-info{
    padding-top: 16px;
}

.ca__contentCard-type {
    background: #E0E6F0;
    border-radius: 20px;
    padding: 4px 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #0B3B98;
    width: max-content;
}