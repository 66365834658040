.ca__planCard {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ca__planCard-info {
    max-width: 50%;
}

.ca__planCard-info-title {
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    &>h4 {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #185ADB;
        margin-bottom: 0;
    }
}

.ca__planCard-info-active {
    margin-left: 10px;
    background: #E0E6F0;
    border-radius: 20px;
    padding: 4px 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #0B3B98;
}

.ca__planCard-info-tokens {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #494E57;
    padding-bottom: 13px;

    span {
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        margin-right: 5px;
    }
}

.ca__planCard-info-cost {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #494E57;

    span {
        font-weight: 700;
        font-size: 18px;
    }
}

// .ca__planCard-tokens {
//     width: 45%;
// }

.ca__planCard-tokens-text {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #8E99AD;

    span {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        color: #494E57;
        margin-right: 5px;
    }

}

.ca__planCard-tokens-line {
    width: 100%;
    height: 7px;
    background: #F0F6FC;
    border-radius: 10px;
    margin-top: 8px;
    margin-bottom: 12px;
}

.ca__planCard-tokens-line-use {
    background: #185ADB;
    border-radius: 10px;
    height: 7px;
    max-width: 100%;
}

.ca__planCard-tokens-date {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #8E99AD;
    text-align: end;
}

.ca__planCard-button-pro {
    background: #F0F6FC;
    border-radius: 4px;
    padding: 12px 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #185ADB;
    border: none;

    &:hover {
        cursor: pointer;
    }
}

.ca__planCard-button-proAnnual {
    background: #185ADB;
    border-radius: 4px;
    padding: 12px 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #fff;
    border: none;

    &:hover {
        cursor: pointer;
    }
}

.ca__planCard-infoAnnual {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #494E57;
    padding-top: 10px;
}

.ca__planCard-info-proanual {
    padding: 4px 8px;
    background: #FFF4D9;
    border-radius: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #BD8E1E;
    margin-left: 10px;
}