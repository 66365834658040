.ca__account {
    // width: 100%;
    display: flex;
    padding: 60px 0;
    align-items: center;
    justify-content: center;
}

// .ca__account-container-flex {
// display: flex;
// justify-content: space-between;
// width: 50%;
// margin: auto;
// }

.ca__account-container {
    background-color: #fff;
    border: 1px solid #E6ECEF;
    border-radius: 8px;
    width: 583px;
    padding: 44px 40px;

    h4 {
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 37px;
        color: #242A30;
        margin-bottom: 28px;
    }
}

.ca__account-inputs {

    // width: 100%;
    label {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #494E57;
        width: 100%;
    }

    input {
        margin-top: 12px;
        margin-bottom: 24px;
        width: 100%;
        box-sizing: border-box;
        border: 1px solid #E0E6F0;
        border-radius: 4px;
        padding: 13.5px 18px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
    }

    input:disabled {
        background-color: #f9f9f997;
    }

    input#email {
        margin-bottom: 0;
    }

    .ca__account-textPassword {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        color: $azulPrimario;
        margin-top: 12px;
        margin-bottom: 32px;

        &:hover {
            cursor: pointer;
        }
    }

    button {
        background: #185ADB;
        border-radius: 4px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #fff;
        // width: 100%;
        padding: 12px 24px;
        border: none;

        &:hover {
            cursor: pointer;
            background: #0B3B98;
        }
    }

    textarea {
        height: 150px;
    }
}

.ca__account-dropzone {
    border: 2px dashed #DDDCFF;
    border-radius: 4px;
    padding: 31px 24px;
    box-sizing: border-box;
}

.ca__account-dropzone-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:hover {
        cursor: pointer;
    }

    img {
        width: 60%;
    }

    p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: $gris1;
        margin-bottom: 16px;
        text-align: center;
        margin-top: 18px;
    }

    .ca__account-dropzone-text {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        text-transform: uppercase;
        color: #185ADB;
        text-align: center;
        margin-bottom: 0;
        margin-top: 0;
    }
}

.ca__container-password {
    background-color: $gris5;
    border-radius: 4px;
    padding: 22px 18px;
    margin-bottom: 32px;
    position: relative;

    &>img {
        position: absolute;
        top: 12px;
        right: 18px;

        &:hover {
            cursor: pointer;
        }
    }
}

.ca__account-inputpass {
    position: relative;

    i {
        color: $negro;
        font-size: 18px;
        position: absolute;
        top: 32.5%;
        right: 18px;

        &:hover {
            cursor: pointer;
        }
    }

    input {
        padding-right: 45px;
    }
}

.ca__account-back {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #8E99AD;
    display: flex;
    align-items: center;
    padding-bottom: 12px;

    &:hover {
        cursor: pointer;
    }
}

.ca__account-footer {
    border-top: 1px solid #E0E6F0;
    margin-top: 50px;
    padding-top: 20px;

    .ca__account-inputs {
        max-height: 0;
        overflow: hidden;
        transition: max-height .5s ease-in;
    }

    .ca__open-form {
        // overflow: visible;
        max-height: 500px;
        transition: max-height 0.5s ease-out;
    }

}

.ca__account-footer-cancel {
    display: flex;
    justify-content: center;

    p {
        color: $gris3;
        text-align: center;
        text-decoration: none;
        display: flex;
        align-items: center;

        &:hover {
            cursor: pointer;
        }
    }

    .icon-close-cancel {
        rotate: -180deg;
        transition: all 0.25s linear;
    }
}