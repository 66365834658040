.ca__header {
    background-color: $blanco;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 22px 21px 22px 19px;
}

.ca__header-logo {
    display: flex;
    align-items: center;
    width: 65%;

    &>img {
        margin-right: 120px;
        width: 28%;

        &:hover {
            cursor: pointer;
        }
    }
}

.ca__header-profile {
    display: flex;
    align-items: center;
    position: relative;
}


//! Styles single project

.ca__singleProject-textNav {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: $gris2;
    text-transform: uppercase;

    &:hover {
        cursor: pointer;
    }
}

.ca__singleProject-title {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: $negro;
    padding-left: 10px;

    img {
        margin-left: 8px;
        width: 18px;

        &:hover {
            cursor: pointer;
        }
    }
}

.ca__header-profile-project {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 26px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    color: #8E99AD;

    &>div {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        color: #494E57;
        display: flex;
        align-items: center;

        &>span {
            color: #8E99AD;
            font-size: 16px;
            line-height: 19px;
            margin-left: 5px;
        }

        img {
            margin-right: 5px;

        }
    }
}

.ca__header-tooltip {
    position: relative;

    &:hover {
        cursor: pointer;

        .ca__header-tiptext {
            visibility: visible;
        }
    }
}

.ca__header-tiptext {
    visibility: hidden;
    position: absolute;
    top: 150%;
    left: -158%;
    z-index: 1;
    background: #242A30;
    border-radius: 4px;
    padding: 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #F0F6FC;
    width: 200px;
    text-align: left;

    &::after {
        content: "";
        position: absolute;
        // background: #242A30;
        right: 50%;
        top: -10px;
        border-bottom: 10px solid #242A30;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;

    }
}


@media only screen and (max-width: 768px) {
    .ca__header-logo {
        width: 95%;
    }
}