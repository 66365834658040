.ca__noProjects {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;

    &>div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
            width: 48px;
            height: 48px;
            margin-bottom: 32px;
        }

        h4 {
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 28px;
            color: $negro;
            margin-bottom: 16px;
        }

        p {
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 21px;
            text-align: center;
            color: $gris1;
            margin-bottom: 40px;
        }

        button {
            background-color: $azulPrimario;
            padding: 16px 24px;
            border: none;
            color: #fff;
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 21px;
            border-radius: 4px;
            &:hover{
                cursor: pointer;
            }
        }

    }
}