.ca__createProject {
    max-width: 879px;
    .ca__second-container{
        margin-left: 0;
        width: 100%;
    }
    .ca__templates{
        padding: 0 7px;
    }
    & >h4 {
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 37px;
        color: #000;
        text-align: center;
    }

    .ca__backtext {
        margin-bottom: 17px;
    }

    .ca__divider {
        margin-bottom: 46px;
    }
}
.ca__createProject-inputs {
    width: 60%;
    margin-top: 46px;
    margin: 46px auto;

    input,
    textarea {
        margin-top: 8px;
        margin-bottom: 20px;
    }

    textarea {
        height: 100px;
    }

    label {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: $negro;
    }
    .ca__principalButton{
        float: right;
    }
}