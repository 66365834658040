// .ca__singleProject{
//     margin-top: 1px;
// }
// .ca__singleProject-container{
//     display: flex;
//     justify-content: space-between;
//     // min-height: 92vh;
//     // background-color: #fff;
// }
// .ca__singleProject-free{
//     justify-content: flex-start;
// }

// @media only screen and (max-width: 768px) {
//     .ca__singleProject-container{
//         flex-direction: column;
//     }

//     .ca__elementsComponent,
//     .ca__editorComponent,
//     .ca__editComponent   {
//         width: 100% !important;
//         margin: 10px;
//     }

// }



// ! NEW STYLES
.ca__single-margin{
    border-left: 4px solid #F8FAFB;;
}
.ca__singleProject{
    display: flex;
}

.ca__proyects-loading, .ca__singleContent-load{
    display: flex;
    align-items: center;
    height: 80vh;
    justify-content: center;
    .sk-fading-circle {
        width: 60px;
        height: 60px;
    }
    h4{
        text-align: center;
        margin-top: 50px;
        font-size: 25px;
    }

}