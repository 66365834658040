//* Font - Family
$font-family: "Karla";

// * Color Palette
$transparente: rgba(255, 255, 255, 0);
$negro: #242A30;
$gris1: #494E57;
$gris2: #8E99AD;
$gris3: #C2CCDD;
$gris4: #E0E6F0;
$gris5: #F0F6FC;
$blanco: #FFFFFF;
$azulPrimario: #185ADB;
$azulPollito: #FFC947;
$rojo: #EC4646;
$azulClaro: #D3E2FF;
$azulOscuro: #0B3B98;
$verde: #11B114;
$verderOscuro: #0C590D;
$verdeClaro: #EEFFEE;
$rojoClaro: #FFE4E4;
$amarilloOscuro: #C99926;
$amarilloClaro: #FFF4D9;