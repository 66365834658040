.ca__emptyState {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 110px;

    img {
        width: 54px;
        margin-bottom: 32px;
    }

    h4 {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        text-align: center;
        color: $negro;
        margin-bottom: 16px;
    }

    p {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: $gris1;
    }
    button{
        margin-top: 30px;
    }

}