.ca__contentCreateForm {
    .ca__backtext {
        margin-bottom: 20px;
        margin-left: -8px;
    }

    input,
    select,
    textarea {
        margin-top: 12px;
        margin-bottom: 28px;
    }

    textarea {
        height: 150px;
    }
}

.ca__contentCreateForm-title {
    display: flex;
    align-items: center;

    i {
        margin-right: 12px;
        font-size: 24px;
    }

    h4 {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        color: #000000;
    }
}

.ca__contentCreateForm-divider {
    height: 1px;
    background-color: $gris4;
    margin-top: 25px;
    margin-bottom: 32px;
}

.ca__contentCreateForm-second {
    margin-top: 15px;

    .ca__principalButton {
        float: right;
    }
}

.ca__formlabel-container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.ca__text-counter {
    font-size: 12px;
    color: $gris2;
    padding-right: 5px;
}

.ca__multipleinput-counter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: -8px;
}

.ca__multiplemargin {
    margin-top: 10px;
}

.ca__multipleinput-margin {
    margin-top: 8px;
}

.ca__lastinputs .ca__multipleinput-counter {
    margin-bottom: 0;
}

.ca__contentCreate-createproject {
    position: relative;
    box-sizing: border-box;
    padding: 18px;
    background-color: #F8FAFB;
    margin-bottom: 20px;

    h4 {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        color: #242A30;
        margin-bottom: 18px;
    }

    label {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #242A30;
    }

    input,
    textarea {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        margin-top: 8px;
    }

    textarea {
        height: 80px;
    }
}

.ca__contentCreate-divider {
    height: 1px;
    background: #E0E6F0;
    margin-bottom: 24px;
    margin-top: 20px;
}

.ca__formCreate-name {
    margin-bottom: 8px!important;
}

.ca__contentCreate-helptext {
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $gris1;
}