.ca__templates {
    // padding: 71px;
    padding: 71px 80px;
    max-width: 1024px;
    width: 100%;
    box-sizing: border-box;
    &>h4 {
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 37px;
        color: #000000;
        text-align: center;
        margin-bottom: 38px;
    }

}
.ca__templates-divider{
    width: 100%;
    height: 1px;
    background-color: $gris4;
    margin-top: 18px;
    margin-bottom: 24px;
}
.ca__templates-flex{
    display: flex;
    flex-wrap: wrap;
}