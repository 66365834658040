.ca__headerProyects {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 13px;
    border-bottom: 2px solid $gris4;
    margin-bottom: 32px;
    .ca__backtext{
        margin-bottom: 8px;
    }

    h4 {
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 37px;
        color: #000;
    }

}