.ca__dashboard {
    padding: 71px 78px;
    max-width: 1400px;
    &>h3 {
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 37px;
        color: #000;
        margin-bottom: 18px;
    }

    &>hr {
        height: 1px;
        background: $gris5;
        border: none;
        margin-bottom: 16px;
    }

    .ca__container-subtitle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 48px;
        margin-bottom: 18px;

        &>h4 {
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 28px;
            color: #000000;
        }
    }

}

.ca__dashboard-textMore {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: $azulPrimario;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    width: max-content;

    i {
        font-size: 18px;
        margin-left: 10px;
    }

    &:hover {
        cursor: pointer;
    }
}

.ca__dashboard-templates {
    display: flex;
    justify-content: space-between;
}

.ca__dashboard-cardTemplate {
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #E0E6F0;
    border-radius: 8px;
    padding: 18px;
    width: 23%;

    &>p {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: $negro;
    }

    &:hover {
        cursor: pointer;
    }
}

.ca__dashboard-card-icon {
    background-color: $gris5;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;

    i {
        font-size: 20px;
        color: $azulPrimario;
    }

}

.ca__dashboard-projects {
    border: 1px solid #E0E6F0;
    border-radius: 8px;
    padding: 1px;
}

.ca__dashboard-cardproject {
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 4px;
    margin-bottom: 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:last-of-type{
        margin-bottom: 0;
    }
    &:hover {
        cursor: pointer;
    }

    // gap: 51px;
}

.ca__dashboard-cardproject-name {
    width: 48%;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #242A30;
    padding: 18px 0 18px 18px;
}

.ca__dashboard-cardproject-date {
    width: 45%;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #8E99AD;
    padding: 18px 0 18px 0;
}

.ca__dashboard-cardproject-icon {
    padding: 18px 18px 18px 0;
    position: relative;
    .ca__gridCardProject-actions-modal{
        top: 42px;
    }
    i {
        color: $gris2;
        font-size: 20px;
    }
}