.ca__container-load {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 130px;
}

.ca__textLoad {
    margin-top: 30px;
    font-size: 24px;
}

.ca__proyects {
    padding: 71px 73px;
    display: flex;
    justify-content: center;
}

.ca__proyects-container {
    // max-width: 1024px;
    width: 100%;
}

// @media screen and (min-width: 2000px) {
//     .ca__proyects-container {
//         max-width: 1600px;
//     }
// }

.ca__proyects-containerProjects {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.ca__projects-headers {
    display: flex;
    align-items: center;
    margin-bottom: 18px;

    p {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: $gris1;
    }

}

.ca__projects-header-name {
    width: 51.2%;
}

.ca__projects-header-category {
    width: 21.3%;
}



@media only screen and (max-width: 768px) {
    .ca__proyects {
        width: 85%;
        margin: 0 auto;
        padding: 20px 0;
        .ca__createContainer{
            width: 100%;
        }
    }
}