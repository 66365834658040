@import url('https://fonts.googleapis.com/css2?family=Karla:wght@400;500;700&display=swap');
@import "./variables";
@import "./login/login.scss";
@import "./header/header";
// ! COMPONENT PROJECT IMPORTS
@import "./proyects/projects";
@import "./proyects/headerProjects";
@import "./proyects/filtersComponent";
@import "./proyects/gridCardProject";
@import "./proyects/noProjects";
@import "./proyects/createProject";
@import "./proyects/noContents";
@import "./proyects/contentCard";
@import "./proyects/contents";
@import "./proyects/createContent";
// ! SINGLE PROJECT IMPORTS
@import "./singleProject/singleProject";
@import "./singleProject/elementsComponent";
@import "./singleProject/edit";
@import "./singleProject/editor";

// ! Modal Imports
@import "./modal/modal";
@import "./modal/createElement";
@import "./modal/warningComponent";
@import "./modalGenerate/modalGenerate";

// ! Import Account styles
@import "./account/account";

// ! Edit Component
@import "./optionsEditComponent/optionsEditComponent";


// ! Spinner Styles
@import "./spinner/spinner";

// ! Message Component Styles
@import "./message/messageComponent";

// ! Counter Component styles
@import "./counter/counter";

// ! User Plan styles
@import "./account/userPlan";

// ! Plan Card Styles
@import "./account/planCard";

// ! Cancel Modal Styles
@import "./modal/cancelModal";

// ! Template Compoonent Styles
@import "./template/template";
@import "./template/cardTemplate";

// ! Sidebar component
@import "./sidebar/sidebar";

// ! Content Form Component
@import "./ContentCreateForm/contentCreateForm";

// ! Dashboard Styles
@import "./dashboard/dashboard";

body {
    font-family: $font-family, sans-serif !important;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #F8FAFB;

    &::-webkit-scrollbar {
        display: none;
    }
}

#root {

    div,
    button,
    p,
    h4,
    h1,
    h2,
    h3,
    h5,
    input,
    textarea,
    select {
        font-family: $font-family, sans-serif !important;
    }
}

sup {
    color: red;
    vertical-align: bottom;
}

input,
select,
textarea {
    font-family: $font-family, sans-serif !important;
    background: #FFFFFF;
    border: 1px solid $gris4;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 14px 18px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    width: 100%;

    &::placeholder {
        color: $gris2;
    }

    &:focus {
        outline: none;
    }
}

textarea {
    resize: none;
}

label {
    display: block;
}

h4 {
    margin: 0;
}

p {
    margin: 0;
}

ul li {
    list-style: none;
}

.ca__principalButton {
    background: $azulPrimario;
    border-radius: 4px;
    color: #fff;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    padding: 12px 24px;
    border: none;

    &:hover {
        background-color: $azulOscuro;
        cursor: pointer;
    }
}

.ca__secondButton {
    background: #F0F6FC;
    border-radius: 4px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #185ADB;
    border: 1px solid #F0F6FC;
    box-sizing: border-box;
    width: max-content;

    i {
        margin-right: 10.5px;
    }

    &:hover {
        cursor: pointer;
        border-color: $azulPrimario;
    }
}

.ca__disableButton {
    background-color: #a8a8a8;

    &:hover {
        cursor: auto;
        background-color: #a8a8a8;
    }
}

.ca__backtext {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: $gris2;
    display: flex;
    align-items: center;
    width: max-content;

    &:hover {
        cursor: pointer;
    }
}

.ca__divider {
    height: 2px;
    width: 100%;
    background-color: $gris4;
}

.ca__principal {
    display: flex;
    // justify-content: end;
}

.ca__second-container {
    width: 83%;
    box-sizing: border-box;
    margin-left: 17%;
    
}

#root select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    background-image: url('../assets/icons/arrow-select.png');
    background-repeat: no-repeat;
    background-position: right center;
    background-position-x: 97%;
}

.ca__mobileEnable {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 13px;

    &>img {
        margin-bottom: 30px;
    }

    &>h4 {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        color: #242A30;
        margin-bottom: 16px;
    }

    &>p {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: #494E57;
        padding-bottom: 32px;
    }

    &>a {
        background: #185ADB;
        border-radius: 4px;
        border: none;
        padding: 14px 20px;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        color: #FFFFFF;
        text-decoration: none;

        &:hover {
            cursor: pointer;
            background-color: #0B3B98;
        }
    }
}

.ca__mobileEnable-header {
    height: 64px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 150px;

}

// .ca__selectInput {
//     position: relative;

//     img {
//         position: absolute;
//         right: 5px;
//         top: 30%;
//     }
// }
div#cb-128861, .cb-box {
    max-width: 100%!important;
}