.ca__modalGenerate {
    background-color: #fff;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04), 0px 12px 16px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    // width: 450px;
    min-height: 75%;
}

.ca__modalgenerate-header {
    text-align: center;
    padding: 24px 0;

    h4 {
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 37px;
        color: #242A30;
    }
}

.ca__modalgenerate-body {
    padding: 58px 148px;
    text-align: center;

    img {
        margin-bottom: 41px;
    }

    p {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: #242A30;
    }
}

@keyframes progreso {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}

@keyframes porcentaje {
    0% {
        content: '0%';
    }
    25% {
        content: '25%';
    }
    50% {
        content: '50%';
    }
    75% {
        content: '75%';
    }
    100% {
        content: '100%';
    }
}
#ca__modalGenerate-container {
    // width: 100%;
    height: 4px;
    margin: 0 auto;
    // border: 1px solid $gris5;
    background-color: $gris5;
}

#ca__modalGenerate-barra {
    width: 0;
    height: 100%;
    background: #185ADB;
    animation: progreso 5s linear infinite;
}