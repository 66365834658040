.ca__cancelModal {
    background-color: #fff;
    border-radius: 4px;
    width: 40%;
    padding: 32px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &>h4 {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        color: #242A30;
        margin-bottom: 17px;
    }

    &>p {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: #242A30;
        padding-bottom: 18px;
    }

    input {
        box-sizing: border-box;
        border: 1px solid #E0E6F0;
        border-radius: 4px;
        padding: 14px 18px;
        width: 100%;
        margin-bottom: 18px;
        font-size: 18px;
    }
}

.ca__cancelModal-close {
    position: absolute;
    top: 32px;
    right: 32px;

    &:hover {
        cursor: pointer;
    }
}

.ca__cancelModal-icon {
    margin-bottom: 17px;
}

.ca__cancelModal-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    button {
        box-sizing: border-box;

        &:hover {
            cursor: pointer;
        }
    }
}

.ca__cancelModal-back {
    background: #F0F6FC;
    border-radius: 4px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #185ADB;
    padding: 12px 27px;
    border: none;
}

.ca__cancelModal-cancel {
    background: #EC4646;
    border-radius: 4px;
    padding: 12px 20px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
    border: none;
}

.ca__cancelModal-suscription {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h4 {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        text-align: center;
        color: #242A30;
        margin-bottom: 18px;
    }

    p {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        color: #242A30;
    }

    button {
        background: #185ADB;
        border-radius: 4px;
        border: none;
        box-sizing: border-box;
        padding: 16px 33px;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        color: #FFFFFF;
        margin-top: 24px;
        margin-left: auto;
        margin-right: auto;
        &:hover{
            cursor: pointer;
        }
    }
}

.ca__cancelModal-text {
    font-style: normal;
    font-weight: 700 !important;
    font-size: 32px !important;
    line-height: 37px !important;
    color: #8E99AD !important;
    text-align: center;
    padding-top: 18px;
}