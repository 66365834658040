.ca__cardtemplate-ultimate{
    margin-right: 0!important;
}
.ca__cardtemplate {
    background: #FFFFFF;
    border: 1px solid #fff;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04), 0px 12px 16px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    padding: 24px;
    width: 31.7%;
    margin-bottom: 27px;
    margin-right: 20px;
    box-sizing: border-box;
    position: relative;
    &:hover{
        border-color: #185ADB;
    }
    i{
        font-size: 28px;
        color: #185ADB;
    }

    h4 {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        color: #000;
        margin-bottom: 12px;
        margin-top: 10px;
    }

    p {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: $gris1;
    }
}
.ca__cardtemplate-active {
    border: 1px solid #11B114;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04), 0px 12px 16px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    background: #EEFFEE;
}

.ca__cardtemplate:hover {
    cursor: pointer;
}
.ca__cardtemplate-ian{  
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: #E0E6F0;   
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding: 4px 8px;
    font-size: 12px;
    color: #0B3B98;
    img {
        margin-right: 3px;
    }
}
.ca_templates-filter{
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    & > div {
        margin-right: 18px;
        padding: 4px 12px;
        border: 1px solid #D3E2FF;
        border-radius: 20px;
        display: flex;
        align-items: center;
        color: #0B3B98;
        font-size: 16px;
        background-color: #fff;
        .ian-icon-white{
            display: none;
        }
        img {
            margin-right: 5px;
        }
        &:hover{
            cursor: pointer;
        }
    }

}
.ca_templates-filter-active{
    color: #fff!important;
    background-color: #185ADB!important;
    .ian-icon-white{
        display: block!important;
    }
    .ian-icon{
        display: none;
    }
}