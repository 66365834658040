.ca__elementsComponent {
    border-radius: 8px 0px 0px 8px;
    padding: 40px 32px 40px 24px;
    width: 24.8%;
    box-sizing: border-box;
    min-height: 92vh;

    &>h4 {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        color: $negro;
        margin-bottom: 16px;
    }

    &>p {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        color: $gris1;
        margin-bottom: 20px;
    }

}

.ca__elementsComponent-objective {
    margin-top: 2px;
    font-size: 11px;
    text-overflow: ellipsis;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

}

.ca__singleProject-bgwhite {
    background-color: $blanco;
}

.ca__elementsComponent-active {
    background-color: $azulOscuro !important;

    .ca__elementsComponent-option-bg {
        background-color: $blanco;
    }

    p {
        color: $blanco !important;
    }

    .ca__elementsComponent-bin svg path {
        fill: #fff !important;
    }
}

.ca__elementsComponent-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #E0E6F0;
    border-radius: 4px;
    margin-bottom: 20px;
    box-sizing: border-box;
    overflow: hidden;

    .ca__elementsComponent-bin svg {
        display: none;

        path {
            fill: $azulPrimario;
        }
    }

    &:hover {
        cursor: pointer;
        background: $gris5;

        .ca__elementsComponent-bin svg {
            display: block;
        }
    }

    &>p {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        color: $negro;
    }

}

.ca__elementsComponent-first {
    display: flex;
    align-items: center;
    padding: 14px 0 14px 14px;
    width: 80%;
}

.ca__elementsComponent-bin {
    margin-right: 14px;
}

.ca__elementsComponent-option-bg {
    background-color: $azulClaro;
    height: 33px;
    width: 33px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    i{
        font-size: 22px;
        color: $azulPrimario;
    }
}

.ca__elementsComponent-aggElement {
    border: 2px dashed #D3E2FF;
    border-radius: 4px;
    padding: 17.5px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        cursor: pointer;
    }

    img {
        margin-right: 15px;
    }

    p {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        color: $azulPrimario;
    }
}

.ca__elementsComponent-description {
    width: 76%;
}

@media screen and (max-width: 1300px) {
    .ca__singleProject-free .ca__elementsComponent{
        margin-right: 2px;
    }
}
@media screen and (min-width: 1400px) {
    .ca__singleProject-free .ca__elementsComponent{
        margin-right: 38px;
    }
}
@media screen and (min-width: 1700px) {
    .ca__singleProject-free .ca__elementsComponent{
        margin-right: 68px;
    }
}
