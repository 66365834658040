.ca__filtersComponent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    
}

.ca__filtersComponent-containerInput {
    padding: 12px 13px;
    border: 1px solid $gris4;
    border-radius: 4px;
    display: flex;
    align-items: center;
    width: 20%;

    input {
        border: none;
        background-color: $transparente;
        margin-left: 9px;
        width: 100%;
        font-size: 16px;
        padding: 0;
    }
}
.ca__distributionActive{
    background-color: $gris4;
    svg path{
        fill: #185ADB!important;
    }
}
