// #8E99AD gris
// #11B114

.ca__optionsEdit {
    display: flex;
    align-items: center;
    padding: 12px 13px;
    border: 1px solid #C2CCDD;
    border-radius: 4px;
    margin-bottom: 8px;
    &:hover {
        cursor: pointer;
    }
    svg {
        margin-right: 12px;
    }

    p {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        color: #8E99AD;
    }
}

.ca__optionEdit-active{
    border-color: #11B114;
    svg path{
        fill: #11B114;
    }
    p{
        color: $negro;
    }
}